<template>
  <div v-if="error">
    <ErrorComponent :message="error" @onClick="goToPICHome" btnTitle="Go Back" />
  </div>
  <div v-else class="login">
    <DgSpinner />
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import { identity } from "@/utils";
import * as Sentry from "@sentry/vue";
import ErrorComponent from "@/engines/common/components/ErrorComponent";

export default {
  components: {
    ErrorComponent,
  },
  computed: {
    ...mapGetters(["serverConfig"]),
  },
  data() {
    return {
      error: null,
    };
  },
  async mounted() {
    const { accessToken } = await identity.login();

    try {
      const response = await this.$http({
        url: `${this.serverConfig.api1}users/login`,
        method: "POST",
        headers: { Authorization: `Bearer ${accessToken}` },
      });

      const searchParams = new URLSearchParams(window.location.search);
      const redirectURL = searchParams.get("redirect_to") || response.data.redirect_url;
      if (redirectURL) {
        const { hostname } = new URL(redirectURL);
        if (hostname.endsWith("dataguard.de") || process.env.NODE_ENV === "development") {
          window.location.replace(redirectURL);
        } else {
          this.error = "Invalid URL";
        }
      }
    } catch (error) {
      Sentry.captureException(error);
    }
  },
  methods: {
    goToPICHome() {
      window.location.replace(this.serverConfig.picHome);
    },
  },
};
</script>

<style lang="scss" scoped>
.login {
  min-width: 1140px;
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
