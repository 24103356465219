<template>
  <div class="dg-error-component">
    <DgPanel>
      <div class="dg-error-component__message">
        {{ finalMessage }}
      </div>
      <div class="text-center">
        <DgButton @click="handleClick">
          {{ btnTitle }}
        </DgButton>
      </div>
    </DgPanel>
  </div>
</template>

<script>
import { Basic, Structural } from "@/dgui-customer-components/";

export default {
  components: {
    DgButton: Basic.DgButton,
    DgPanel: Structural.DgPanel,
  },
  props: {
    status: {
      type: Number,
      default: null,
    },
    message: {
      type: String,
      default: "",
    },
    btnTitle: {
      type: String,
      default: "try again",
    },
  },
  data() {
    let finalMessage = this.message;

    if (!finalMessage && this.status !== undefined) {
      switch (this.status) {
        case 404:
          finalMessage = "Not found!";
          break;

        case 500:
          finalMessage = "Server is down!";
          break;

        default:
          finalMessage = "Something happend!";
          break;
      }
    }

    return {
      finalMessage,
    };
  },
  methods: {
    handleClick() {
      this.$emit("onClick");
    },
  },
};
</script>
<style lang="scss" scoped>
.dg-error-component {
  @media (min-width: 768px) {
    max-width: 75%;
    margin: 0 auto;
  }

  &__message {
    text-align: center;
    font-size: 20px;
    margin-top: 20px;
    margin-bottom: 40px;
  }
}
</style>
